<template>
    <div class="modal-mask2">
        <div class="modal-container2">
            <span class="material-icons-outlined delete-user-icon">report_gmailerrorred</span>
            <div class="delete-user-title">{{title}}</div>
            <div class="delete-user-subTitle">{{subTitle}}</div>
            <div class="delete-user-buttons">
                <StandardButton class="delete-user-button" :action="close" text="Cancelar" />
                <StandardButton class="delete-user-button" :action="remove" text="Sim, quero remover!" />
            </div>
        </div>
    </div>
</template>

<script>
import StandardButton from '@/components/ui/buttons/StandardButton.vue';
export default {
    name: "DeleteUserModal",
    props: [ "title", "subTitle", "close", "remove" ],
    components: {StandardButton}
}
</script>

<style>
.modal-mask2 {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
}
.modal-container2 {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    padding: 5vh 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.delete-user-icon{
    font-size: 10vw;
    color: var(--primary-color);
}
.delete-user-title{
    font-weight: 600;
    font-size: 2em;
    color: #605F5F;
}
.delete-user-subTitle{
    font-weight: 500;
    font-size: 1.5em;
    color: #605F5F;
    margin-top: 1vh;
}
.delete-user-buttons{
    display: flex;
    gap: 3vw;
    margin-top: 3vh;
}
.delete-user-button{font-size: 1.1em;}

@media only screen and (max-width: 800px){
    .modal-mask2{font-size: 2vw;}
}
@media only screen and (max-width: 450px){
    .modal-mask2{font-size: 3vw;}
    .modal-container2{width: 120vw;}
}
</style>