<template>
    <div class="modal-mask">
        <div class="modal-container">
            <div class="new-resale-modal-header">
                <div v-if="VIEW == 'USER_LIST'">Meus Usuários</div>
                <div v-else-if="VIEW == 'USER_FORM'">Novo Usuário</div>
                <span v-on:click="close()" class="material-icons new-resale-modal-close">cancel</span>
            </div>
            <div class="new-resale-modal-body" v-if="loading">
                <cc-loader style="margin-top: 10vh;"/>
            </div>
            <div class="new-resale-modal-body" v-else>
                <UserForm
                    ref="user-form"
                    @close="current_user = null;VIEW = 'USER_LIST'"
                    v-if="current_user && VIEW == 'USER_FORM'"
                    :profiles="profiles"
                    :resale="resale"
                    @reload="() => !user_id ? load() : $emit('reload') "
                    :current_user="current_user" />
                
                <div v-else class="new-resale-table">
                    <div class="new-resale-table-header">
                        <div class="new-resale-id-with">ID</div>
                        <div class="new-resale-user-with">Usuário</div>
                        <div class="new-resale-login-with">Login</div>
                        <div class="new-resale-password-with">Senha</div>
                        <div class="new-resale-perfil-with">Perfil</div>
                        <div class="new-resale-actions-with">Ações</div>
                    </div>
                    <no-data-available v-if="users && users.length == 0" :text="'Sem usuários cadastrados'" />
                    <div v-else class="new-resale-table-body" :class="getSpecialBackground(index)" v-for="(user, index) in users" :key="user.usu_id">
                        <div class="new-resale-id-with new-resale-mobile-row">
                            <div class="new-resale-mobile-title">ID</div>
                            {{ user.id }}
                        </div>
                        <div class="new-resale-user-with new-resale-mobile-row">
                            <div class="new-resale-mobile-title">Usuário</div>
                            {{ user.name }}
                        </div>
                        <div class="new-resale-login-with new-resale-mobile-row">
                            <div class="new-resale-mobile-title">Login</div>
                            {{ user.login }}
                        </div>
                        <div class="new-resale-password-with new-resale-mobile-row">
                            <div class="new-resale-mobile-title">Senha</div>
                            {{  showPassword ? user.decrypted_password : '******' }}
                            <span v-if="resaleId < 2 && !showPassword" class="material-icons-outlined new-resale-password-icon" v-on:click="showPassword = !showPassword">visibility_off</span>
                            <span v-else-if="resaleId < 2 && showPassword" class="material-icons-outlined new-resale-password-icon" v-on:click="showPassword = !showPassword">visibility</span>
                        </div>
                        <div class="new-resale-perfil-with new-resale-mobile-row">
                            <div class="new-resale-mobile-title">Perfil</div>
                            {{ user.profile ? user.profile.name : '-' }}
                        </div>
                        <div class="new-resale-actions-with new-resale-mobile-row">
                            <div class="new-resale-mobile-title">Ações</div>
                            <div>
                                <span class="material-icons-outlined new-resale-actions-edit" title="Editar Usuário" @click="edit(user)">edit</span>
                                <span class="material-icons-outlined new-resale-actions-delete" title="Remover Usuário" @click="deleteUser = user">delete</span>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div class="new-resale-modal-body-buttons">
                    <StandardButton class="new-resale-modal-mobile-button" v-if="VIEW == 'USER_LIST'" :action="close" text="Cancelar"/>
                    <StandardButton class="new-resale-modal-mobile-button" v-else-if="VIEW == 'USER_FORM'" :action="() => {current_user = null;VIEW = 'USER_LIST'}" text="Voltar"/>
                    <StandardButton class="new-resale-modal-mobile-button" v-if="VIEW == 'USER_LIST'" :action="add" text="Adicionar"/>
                    <StandardButton class="new-resale-modal-mobile-button" v-else-if="VIEW == 'USER_FORM'" :action="save" text="Salvar"/>
                </div>
            </div>
        </div>
        <DeleteUserModal
            v-if="deleteUser" 
            :remove="() => {seller_svc.remove(resale.id, deleteUser.id).then(() => {load_sellers(); deleteUser = undefined})}"
            :close="() => deleteUser = undefined" 
            :subTitle="deleteUser.name"
            title="Tem certeza que quer remover este usuário?"/>
    </div>
	<!-- <cc-modal :modal="modal" >
        <div slot="header-actions">
            <div class="row">
                <div class="col actions">
                    <cc-button :content="'Voltar'"
                        :icon="'fas fa-chevron-left'"
                        @click="current_seller = null;VIEW = 'USER_LIST'"
                        :classes="'fill default fixed'"
                        v-if="VIEW != 'USER_LIST' && !user_id" />

                    <cc-button :content="'Fechar'"
                        @click="close();current_seller = null;VIEW = 'USER_LIST'"
                        :classes="'fill danger-outline-no-hover fixed'"
                        v-if="VIEW == 'USER_LIST'" />

                    <cc-button
                        v-if="VIEW == 'USER_FORM'"
                        :nolink="true"
                        :content="'Salvar'"
                        @click="save"
                        :classes="'thin ml-2 theme-3 fixed'" />
                    <cc-button
                        v-if="VIEW != 'USER_FORM'"
                        :nolink="true"
                        :content="'Adicionar'"
                        @click="add"
                        :classes="'thin ml-2 theme-3 fixed'" />
                </div>
            </div>
        </div>
        <div slot="body" class="container-fluid pt-0 users" id="users-content">
            <div class="row">
                <UserForm
                    ref="user-form"
                    @close="current_user = null;VIEW = 'USER_LIST'"
                    v-show="current_user && VIEW == 'USER_FORM'"
                    :profiles="profiles"
                    :resale="resale"
                    @reload="() => !user_id ? load() : $emit('reload') "
                    :current_user="current_user" />

                <div class="col" id="users" v-show="VIEW == 'USER_LIST' && !user_id ">
                    <div class="list-group">
                        <cc-loader v-show="loading"/>
                        <no-data-available v-if="!loading && users && users.length == 0" :text="'Sem usuários cadastrados'" />
                        <table class="table" v-show="!loading && users && users.length > 0">
                            <thead>
                                <tr>
									<th class="text-left px-3">ID</th>
									<th class="text-left px-3">Usuário</th>
									<th class="text-left px-3">Login</th>
                                    <th class="text-left px-3">Senha</th>
									<th class="text-left px-3">Perfil</th>
									<th colspan="2"></th>
								</tr>
                            </thead>
                            <tbody>
                                <tr v-for="user in users" :key="user.usu_id">
									<td class="text-left px-3" ><b>{{ user.id }}</b></td>
									<td class="text-left px-3">{{ user.name }}</td>
									<td class="text-left px-3 text-uppercase">{{ user.login }}</td>
                                    <td class="text-left px-3 text-uppercase">{{ user.decrypted_password }}</td>
									<td class="text-left px-3"><b>{{ user.profile ? user.profile.name : '-' }}</b></td>
									<td>
                                        <div class="actions">
                                            <i class="fas fa-edit" aria-hidden="true" title="Editar Usuário" @click="edit(user)"></i>
                                            <i title="Remover Usuário"
                                                class="fa fa-trash"
                                                @click="remove(user)"></i>

                                        </div>
                                    </td>
								</tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
	</cc-modal> -->
</template>

<script>

import UserForm from "./_user.form.vue";
import DeleteUserModal from "./DeleteUserModal.vue";
import ResaleService from "@/services/v3/resales/resale.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import SellerService from "@/services/v3/resales/seller.service";
import AuthService from '@/services/AuthService'
import Pagination from "@/components/cliente/base-components/Pagination";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapState } from 'vuex'
import StandardButton from '@/components/ui/buttons/StandardButton.vue';
import * as CONSTANTS from "@/constants/constants";

export default {
	mixins: [ loaderMixin ],
	props: {
		resale: {
			type: Object,
			required: true
		},
        user_id: { type: Number }
	},
	components: {
		ValidationProvider,
		ValidationObserver,
        UserForm,
		ccPagination : Pagination,
        StandardButton,
        DeleteUserModal
   	},
  	data() {
		return {
            users: [],
            message: null,
			current_user: null,
			loading: false,
			profiles: [],
            VIEW: "USER_LIST",
            service: new ResaleService(),
            seller_svc: new SellerService(),
            auth_svc: new AuthService(),
            resaleId: localStorage.getItem('revenda'),
            showPassword: false,
            modal: {
				title: `meus usuários`,
				subtitle: `Gerencie seus usuários`,
				icon_title: 'fas fa-users',
				cancel_text: 'Voltar',
                nobackbutton: true,
				style: {
					width: "90%",
					height: "fit-content"
				}
			},
            deleteUser: undefined
		};
	},
    computed: {
        ...mapState(['user'])
    },
  	methods: {
        getSpecialBackground (index) {
            if (index % 2 == 0) return 'page-table-line-special'
        },
        add() {
            this.VIEW = 'USER_FORM'
            this.current_user = { resale: this.resale };
            this.update_modal({ title: `Novo Usuário` })
        },
        edit(user) {
            this.VIEW = 'USER_FORM'
            this.current_user = { ...user, resale: this.resale }
            this.update_modal({ title: `${user.name}` })
        },
		close() {
			this.$emit("close");
		},

        update_modal(new_settings) {
            this.modal = { ...this.modal, ...new_settings }
        },
		load_sellers() {
			this.loading = true
			return this.service.load_sellers(this.resale.id).then(response => response.data).then(data => {
                data.forEach(d => d.decrypted_password = d.password)
				this.load_profiles().then(() => {
					this.users = data;
				}).then(() => {
					this.loading = false
				})
			}).catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
		},
        load_profiles() {
			return this.service.load_profiles(this.resale.id).then(response => response.data).then(data => {
				this.profiles = data
			})
		},
        async save() {
			this.present_loader("Salvando usuário...")
			this.message = null

            let callback = data => {
				this.dismiss_loader()
                const notification = {
                    type: 'success',
                    message: "Dados salvos com sucesso!"
                }
                this.$store.dispatch('notification/add', notification)
                this.VIEW = 'USER_LIST'
			}

            let callback_error = err => {
				this.dismiss_loader()
                let message = 'Ops! Houve um erro ao salvar o usuário, tente novamente mais tarde!'
                if(err.response && err.response.data && err.response.data.errors) {
                    let errors = err.response.data.errors
                     message = Object.values(errors).flat()[0]
				}
                const notification = {
                    type: 'error',
                    message: message
                }
                this.$store.dispatch('notification/add', notification)
			}
            return this.seller_svc.save({
                ...this.current_user,
                resale: this.resale,
                profile: { id: CONSTANTS.DEFAULT_RESALE_USER_PROFILE_ID }
            })
            .then(() => this.load_sellers())
            .then(callback, callback_error)
		},
	},
	mounted() {
		this.load_sellers()
	}
};
</script>

<style lang="scss" scoped>
	@import "./management.modal";
    .modal-mask {
        position: fixed;
        z-index: 4;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1vw;
    }
    .modal-container {
        width: 85vw;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
    }
    .page-table-line-special{background: #F7F7F7;}
    .new-resale-modal-header{
        background-color: var(--primary-color);
        border-radius: 10px 10px 0 0;
        padding: 1vh 1vw;
        color: white;
        font-size: 2em;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .new-resale-modal-close{
        cursor: pointer;
        font-size: 1.2em;
    }
    .new-resale-modal-body{
        padding: 5vh 3vw;
        height: 75vh;
    }
    .new-resale-password-icon{
        margin-left: 1vw;
        font-size: 1.5em;
        cursor: pointer;
    }
    .new-resale-table{
        height: 80%;
        overflow-x: auto;
    }
    .new-resale-modal-body-buttons{
        display: flex;
        justify-content: flex-end;
        gap: 4vw;
        margin-top: 5vh;
    }
    .new-resale-table-header{
        display: flex;
        padding: 2vh 0;
        font-size: 1.3em;
        font-weight: 600;
        color: #605F5F;
        padding-left: 2vw;
    }
    .new-resale-id-with{width: 10%;}
    .new-resale-user-with{width: 22%;}
    .new-resale-login-with{flex: 1;}
    .new-resale-password-with{
        width: 15%;
        display: flex;
        align-items: center;
    }
    .new-resale-perfil-with{width: 17%;}
    .new-resale-actions-with{width: 10%;}
    .new-resale-mobile-title{ display: none; } 
    .new-resale-table-body{
        display: flex;
        padding: 2vh 0;
        font-size: 1em;
        font-weight: 500;
        color: #605F5F;
        padding-left: 2vw;
    }
    .new-resale-actions-edit{
        cursor: pointer;
        color: #606060;
        margin-right: 1vw;
        font-size: 1.5em;
    }
    .new-resale-actions-delete{
        font-size: 1.5em;
        cursor: pointer;
        color: #CB4243;
    }

    @media only screen and (max-width: 800px) {
        .modal-mask{font-size: 2vw;}
        .new-resale-table-header{display: none;}
        .new-resale-table-body{
            flex-direction: column;
            gap: 1vh;
        }
        .new-resale-mobile-row{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            flex: unset;
            padding-right: 2vw;
        }
        .new-resale-mobile-title{
            font-size: 1.2em;
            display: flex;
        }
    }
    @media only screen and (max-width: 450px) {
        .modal-mask{font-size: 3vw;}
        .modal-container{width: 115vw;}
        .new-resale-modal-mobile-button{ width: 50%; }
        
    }
    
</style>