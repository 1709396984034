<template>
    <div class="new-resale-table">
        <div class="new-resale-modal-body-inputs-line">
            <StandardInput style="width: 100%;" :action="setName" :value="current_user.name" :auto="true" class="new-resale-modal-input" title='Nome' type="text" />
        </div>
        <div class="new-resale-modal-body-inputs-line">
            <StandardInput :action="setPhone" :value="current_user.phone"  mask="(##) #.####-####" :auto="true" class="new-resale-modal-input" title='Telefone' type="text" />
            <StandardInput :action="setEmail" :value="current_user.email" :auto="true" class="new-resale-modal-input" title='Email' type="text" />
        </div>
        <div class="new-resale-modal-body-inputs-line">
            <StandardInput :action="setLogin" :value="current_user.login"  :auto="true" class="new-resale-modal-input" title='Login' type="text" />
            <StandardInput :action="setPassword" :value="current_user.decrypted_password"  :auto="true" class="new-resale-modal-input" title='Senha' type="text" />
        </div>
    </div>
    <!-- <div id="user" class="col">
        <div class="list-group">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form action="" v-if="current_user" @submit.prevent="handleSubmit(save)">
                    <div class="row d-flex justify-content-center">
                        <div class="col-md-3 col-lg-4">
                            <label>Nome:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input type="text" v-model="current_user.name"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-md-3 col-lg-4">
                            <label>Email:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input type="text" v-model="current_user.email"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-md-3 col-lg-4">
                            <label>Login:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input type="text" v-model="current_user.login"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="row d-flex justify-content-center">
                        <div class="col-md-3 col-lg-4">
                            <label>CPF:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input v-mask="'###.###.###-##'" type="text" v-model="current_user.cpf"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-md-3 col-lg-4" v-if="!resale">
                            <label>Perfil:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v" name="prof">
                                <cc-select-v2 :tracker="'id'" :text="'name'" v-model="current_user.profile" :options="profiles" ></cc-select-v2>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="row py-3 d-flex justify-content-center">
                        <div class="col-md-3 col-lg-4">
                            <label>Telefone:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input type="text" v-mask="'(##) #.####-####'" v-model="current_user.phone"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-md-3 col-lg-4">
                            <label>Senha:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input type="password" v-model="current_user.decrypted_password"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                </form>
            </ValidationObserver>
        </div>
    </div> -->
</template>
<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import StandardInput from '@/components/ui/inputs/StandardInputV2.vue';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';

export default {
    mixins: [ loaderMixin ],
    components: {
        ValidationProvider,
		ValidationObserver,
        StandardInput
    },
    props: [ "current_user", "profiles", "resale" ],
    data() {
        return {
        }
    },
    methods: {
        setName(value){this.current_user.name = value},
        setCpf(value){this.current_user.cpf = value},
        setPhone(value){this.current_user.phone = value},
        setEmail(value){this.current_user.email = value},
        setLogin(value){this.current_user.login = value},
        setPassword(value){this.current_user.decrypted_password = value},
    }
}
</script>

<style>
.new-resale-modal-body-inputs-line{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vh;
}
.new-resale-modal-input{ width: 45%; }

@media only screen and (max-width: 450px){
    .new-resale-modal-body-inputs-line{
        flex-direction: column;
        gap: 1vh;
    }
    .new-resale-modal-input{ width: 100%; }
}
</style>
