import http from "../http";
import Service from "../../Service";

/**
 * @type ResalesService
 */
export default class ResaleService extends Service {

	list(params) {
		return http.get(`/admin/resales`, {
			headers: this.getHeaders(),
            params
		});
	}

    show(id) {
		return http.get(`/admin/resales/${id}`, {
			headers: this.getHeaders()
		});
	}

    create(resale) {
		return http.post(`/admin/resales`, resale, {
			headers: this.getHeaders()
		});
	}

    update(resale) {
		return http.put(`/admin/resales/${resale.id}`, resale, {
			headers: this.getHeaders()
		});
	}

    remove(resale_id){
        return http.delete(`/admin/resales/${resale_id}`,{
			headers: this.getHeaders()
		});
    }

    load_sellers(resale_id) {
		return http.get(`/admin/resales/${resale_id}/sellers`, {
			headers: this.getHeaders()
		});
	}

    load_profiles(resale_id) {
		return http.get(`admin/resales/${resale_id}/profiles`, {
			headers: this.getHeaders()
		});
	}


}
