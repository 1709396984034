import http from "../http";
import Service from "../../Service";

/**
 * @type BuyerService
 */
export default class SellerService extends Service {

    create(seller) {
		return http.post(`/admin/resales/${seller.resale.id}/sellers`, seller, {
            headers: this.getHeaders()
        });
	}

    update(seller) {
		return http.put(`/admin/resales/${seller.resale.id}/sellers/${seller.id}`, seller, {
            headers: this.getHeaders()
        });
	}

    save(seller) {
		return seller.id ? this.update(seller) : this.create(seller)
	}

    remove(resale_id, id) {
		return http.delete(`/admin/resales/${resale_id}/sellers/${id}`, {
			headers: this.getHeaders()
		});
	}

}
